import { useState, useEffect, useMemo, useContext } from "react";

import "./app.css";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import CouponManagement from "layouts/coupon-management";
import AddCoupon from "layouts/coupon-management/addCoupon";
import CouponUpdate from "layouts/coupon-management/update";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-small.png";
import brandDark from "assets/images/logo-small.png";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";
import Login from "auth/login";
import { AuthContext } from "context";
import OrganizationManagement from "layouts/organization-management";
import UserManagement from "layouts/user-management";
import MetaTags from "react-meta-tags";
import PlanUpdate from "layouts/plan-management/update";
import AddPlan from "layouts/plan-management/addPlan";
import OrgUpdate from "layouts/organization-management/update";
import UserUpdate from "layouts/user-management/update";
import PromptManagement from "layouts/prompt-management";
import ToneManagement from "layouts/tone-management";

import CreditManagement from "layouts/credit-management/credit";
import AddCredit from "layouts/credit-management/addCredit";
import CreditUpdate from "layouts/credit-management/updateCredit";
import CreditHistory from "layouts/credit-history/creditHistory";
import PartnerManagement from "layouts/partner-management/partner";
import AddPartner from "layouts/partner-management/addPartner";
import UpdatePartner from "layouts/partner-management/updatePartner";
import AddPrompt from "layouts/prompt-management/addPrompt";
import UpdatePrompt from "layouts/prompt-management/update";
import UploadPrompt from "layouts/prompt-management/prompt-upload";
import AddHiddenPrompt from "layouts/prompt-management/addHiddenPrompt";
import Profile from "layouts/profile/profile";
import ForgotPassword from "auth/login/forgotPassword";
import ResetPassword from "auth/login/resetPassword";
import Dashboard from "layouts/dashboard";
import About from "examples/Footer/about";
import TermsAndConditions from "examples/Footer/termsAndConditions";
import PrivacyPolicy from "examples/Footer/privacyPolicy";

export default function App() {
  const authContext = useContext(AuthContext);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
    if (localStorage.getItem("token") == null) {
      authContext.logout();
      navigate("/auth/login");
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  // setupAxiosInterceptors(() => {
  //   authContext.logout();
  //   navigate("/auth/login");
  // });

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      {direction === "rtl" ? (
        // <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="GPT Admin Panel"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                className="admin_sidebar"
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="login" element={<Navigate to="/auth/login" />} />
            <Route path="reset-password" element={<Navigate to="/auth/reset-password" />} />
            <Route path="forgot-password" element={<Navigate to="/auth/forgot-password" />} />
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ThemeProvider>
      ) : (
        // </CacheProvider>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="GPT Admin Panel"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/login" element={<Login />} />
            <Route
              exact
              path="organization-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <OrganizationManagement />
                </ProtectedRoute>
              }
              key="organization-management"
            />
            <Route
              exact
              path="member-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <UserManagement />
                </ProtectedRoute>
              }
              key="member-management"
            />
            <Route
              exact
              path="plan-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <PlanUpdate />
                </ProtectedRoute>
              }
              key="plan-update/:id"
            />
            <Route
              exact
              path="add-plan"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddPlan />
                </ProtectedRoute>
              }
              key="add-plan"
            />

            <Route
              exact
              path="prompt-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <PromptManagement />
                </ProtectedRoute>
              }
              key="prompt-management"
            />
            {/* <Route
              exact
              path="add-hidden-prompt"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddHiddenPrompt />
                </ProtectedRoute>
              }
              key="add-hidden-prompt"
            /> */}

            <Route
              exact
              path="prompt-management-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <UpdatePrompt />
                </ProtectedRoute>
              }
              key="prompt-management-update"
            />
            <Route
              exact
              path="add-prompt-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddPrompt />
                </ProtectedRoute>
              }
              key="add-prompt-management"
            />

            <Route
              exact
              path="upload-prompt"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <UploadPrompt />
                </ProtectedRoute>
              }
              key="upload-prompt"
            />
            <Route
              exact
              path="coupon-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <CouponManagement />
                </ProtectedRoute>
              }
              key="coupon-management"
            />
            <Route
              exact
              path="add-coupon-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddCoupon />
                </ProtectedRoute>
              }
              key="add-coupon-management"
            />
            <Route
              exact
              path="update-coupon-management/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <CouponUpdate />
                </ProtectedRoute>
              }
              key="update-coupon-management"
            />


            <Route
              exact
              path="/"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <Dashboard />
                </ProtectedRoute>
              }
              key="/"
            />

            <Route
              path="partner-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <PartnerManagement />
                </ProtectedRoute>
              }
              key="partner-management"
            />
            <Route
              exact
              path="add-partner"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddPartner />
                </ProtectedRoute>
              }
              key="add-partner"
            />
            <Route
              exact
              path="partner-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <UpdatePartner />
                </ProtectedRoute>
              }
              key="partner-update/:id"
            />

            <Route
              exact
              path="uses-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <CreditManagement />
                </ProtectedRoute>
              }
              key="uses-management"
            />
            <Route
              exact
              path="add-uses"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddCredit />
                </ProtectedRoute>
              }
              key="add-uses"
            />
            <Route
              exact
              path="uses-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <CreditUpdate />
                </ProtectedRoute>
              }
              key="uses-update/:id"
            />
            <Route
              exact
              path="profile"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <Profile />
                </ProtectedRoute>
              }
              key="profile"
            />

            <Route
              exact
              path="organization-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <OrgUpdate />
                </ProtectedRoute>
              }
              key="organization-update/:id"
            />
            <Route
              exact
              path="member-update/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <UserUpdate />
                </ProtectedRoute>
              }
              key="member-update/:id"
            />

            <Route
              exact
              path="tone-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <ToneManagement />
                </ProtectedRoute>
              }
              key="tone-management"
            />
            <Route
              exact
              path="add-tone"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <AddPlan />
                </ProtectedRoute>
              }
              key="add-tone"
            />
            <Route
              exact
              path="credit-history/:id"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <CreditHistory />
                </ProtectedRoute>
              }
              key="credit-history/:id"
            />
            <Route
              exact
              path="about-us"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <About />
                </ProtectedRoute>
              }
              key="about-us"
            />
            <Route
              exact
              path="terms-and-conditions"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <TermsAndConditions />
                </ProtectedRoute>
              }
              key="terms-and-conditions"
            />
            <Route
              exact
              path="privacy-policy"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <PrivacyPolicy />
                </ProtectedRoute>
              }
              key="privacy-policy"
            />

            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ThemeProvider>
      )}
    </>
  );
}
