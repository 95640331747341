import { Link } from "react-router-dom";
// @mui material components
import {
  Modal,
  Button,
  Card,
  Grid,
  Box,
  FormControl,
  IconButton,
  Paper,
  FormLabel,
} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InputLabel from "@mui/material/InputLabel";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from 'react-data-table-component';
import LoadingData from "../../loader/loader";
// import { Button, Form } from "react-bootstrap";
import axios from "../../axios/axios";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// Data
import authorsTableData from "layouts/user-management/data";
import { useState, useEffect } from "react";

const UserManagement = () => {
  const { columns, rows, load, getFilterUsers, getUsers } = authorsTableData();

  const [open, setOpen] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addMemberData, setAddMemberData] = useState({
    email: "",
  });
  const [errors, setError] = useState({
    emailError: false,
    orgIdError: false,
  });
  const [notification, setNotification] = useState(false);
  const [filterData, setfilterData] = useState({
    email: "",
    orgId: "all"
  });
  const tableData = {
    columns: columns,
    data: rows
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddMemberData("")
  };

  const validation = () => {
    let newErrors = { ...errors };
    newErrors.orgIdError = filterData.orgId.trim().length === 0;

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    newErrors.emailError =
      !addMemberData.email.trim() || !emailRegex.test(addMemberData.email);

    setError(newErrors); // Update the errors state with new validation results

    // Return a boolean indicating if the form is valid
    return !newErrors.orgIdError && !newErrors.emailError;
  };

  const handleEmailSend = async (e) => {
    e.preventDefault();

    const paramsData = {
      org_id: filterData.orgId,
      email: addMemberData.email,
    };
    // Submit the form or perform other actions
    if (validation()) {
      setLoader(true);
      await axios
        .post("/admin/member-add", paramsData)
        .then((res) => {
          setLoader(false);
          setTimeout(() => {
            handleClose();
          }, 3000);
          getUsers()
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
      setNotification(true);
    }
  };

  useEffect(() => {
    if (notification === true) {
      setTimeout(() => {
        setNotification(false);
      }, 2000);
    }
  }, [notification]);

  const getOrganization = async () => {
    setLoader(true);
    await axios
      .get("/admin/prompt-org-list")
      .then((res) => {
        setLoader(false);
        setOrganization(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const changeHandler = (e) => {
    console.log(e);
    setfilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
    getFilterUsers((e.target.value == 'all') ? '' : e.target.value);
  };
  const changeHandlerEmail = (e) => {
    console.log(e);
    setAddMemberData({
      ...addMemberData,
      [e.target.name]: e.target.value,
    });
  };
  const tableStyle = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox className="primary_container" pt={3} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {(load || loader) && <LoadingData />}
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{flexWrap: 'wrap'}}
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Members Management
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="button"
                    onClick={handleOpen}
                  >
                    Add Member
                  </MDButton>

                  <Modal
                    className="modal"
                    open={open}
                    onClose={handleClose}
                  >
                    <Paper className="modal-dialog">
                      <Box className="modal-content"> 
                        <Box className="modal-header">
                          <MDTypography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Add Member
                          </MDTypography>
                          <IconButton 
                            className="close_button"
                            onClick={handleClose}
                          >
                            <CloseOutlinedIcon/>
                          </IconButton >
                        </Box>                      
                        <Box className="modal-body">
                          {notification && (
                            <Box mb={2}>
                              <MDAlert color="success" className="alert__box">Invitation Sent successfuly</MDAlert>
                            </Box>
                          )}
                          <FormControl className="mb-3" fullWidth>
                            <FormLabel className="form-label">Select Your Organization<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                            <Select
                              id="formBasicOrganization"
                              name="orgId"
                              value={filterData.orgId}
                              onChange={changeHandler}
                              className="member_select_box"
                              style={{ height: 44 }}
                            >
                              <MenuItem value="">Select</MenuItem>
                              {organization.map((val, i) => (
                                <MenuItem key={i} value={val._id}>
                                  {val.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.orgIdError && (
                              <MDTypography
                                variant="caption"
                                color="error"
                                fontWeight="light"
                              >
                                The organization name can not be null
                              </MDTypography>
                            )}
                          </FormControl>

                          <Box>
                            <FormControl fullWidth>
                              <FormLabel className="form-label">Member Email<MDTypography color="error" variant="body2" sx={{display: 'inline-block'}}>*</MDTypography></FormLabel>
                              <MDInput
                                type="text"
                                name="email"
                                value={addMemberData.email}
                                onChange={changeHandlerEmail}
                                error={errors.emailError}
                              />
                              {errors.emailError && (
                                <MDTypography
                                  variant="caption"
                                  color="error"
                                >
                                  The member email can not be null
                                </MDTypography>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className="modal-footer">
                          <MDButton
                            onClick={handleEmailSend}
                            type="button"
                            className="type_submit_butt"
                            color="info"
                            //variant="gradient"
                          >
                            Send Invitation
                          </MDButton>
                        </Box>
                      </Box>
                    </Paper>
                  </Modal>
                </MDBox>

                <MDBox pt={3}>
                  <FormControl sx={{ mx: 2, minWidth: 300 }}>
                    <InputLabel id="demo-simple-select-label" >Select Organization / Partners</InputLabel>
                    <Select 
                      labelId="demo-simple-select-label"
                      id="formBasicOrganization"
                      name="orgId"
                      value={filterData.orgId}
                      onChange={changeHandler}
                      className="member_select_box mt-2"
                      label="Select Organization / Partners"
                      style={{ height: 44 }}
                    >
                      <MenuItem key={0} value="all">All</MenuItem>
                      {organization.map((val, i) => (
                        <MenuItem key={i + 1} value={val._id}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                  <DataTableExtensions {...tableData} filter={true} export={false} print={false} filterPlaceholder="Search Here..">
                    <DataTable
                      // table={{ columns, rows }}
                      // isSorted={true}
                      // entriesPerPage={true}
                      // showTotalEntries={true}
                      // noEndBorder
                      columns={columns}
                      data={rows}
                      pagination
                      customStyles={tableStyle}
                      highlightOnHover
                    />
                  </DataTableExtensions>

                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default UserManagement;
